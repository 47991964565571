import {
  signIn as signInService,
  signOut as signOutService,
} from "next-auth/react"
import { authSignIn, authSignOut } from "shared-utils/authentication"

import { AUTH as AUTH_API } from "@/config/api"
import { AUTH } from "@/config/client"

const loginProvider = {
  auth0: "auth0",
  refreshToken: "refreshToken",
}
export type TProvider = keyof typeof loginProvider

export const signOut = (redirect?: boolean) => {
  return signOutService({
    redirect,
  }).finally(() =>
    authSignOut({ domain: AUTH_API.DOMAIN, baseUrl: AUTH.BASE_URL })
  )
}

type TSignIn = {
  callbackUrl?: string
}

export const signIn = async (provider: TProvider, props?: TSignIn) => {
  const signInOptions = await authSignIn(provider, {
    ...props,
    baseUrl: AUTH.BASE_URL,
    domain: AUTH_API.DOMAIN,
  })
  if (signInOptions) {
    signInService("auth0", { ...signInOptions })
  }
}
